<template>
    <div class="sup_content feature-list">
        <div class="sup_page_heading">
            <h2>Package Features</h2>
        </div>
        <div class="sup_inner_content">
            <!--- Show Package Type Table Start --->
            <DataTable class="p-datatable" :value="allFeatureData.data" :rowHover="true" :loading="allFeatureData.loading" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rows="10" responsiveLayout="scroll" removableSort >
                <template #loading>
                    Loading Packages data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-end">
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allFeatureData.search" placeholder="Search Package Type" @keyup.enter="fetchAllFeatures(allFeatureData.activityStatus, 'search')"/>
                            </span>
                            <Button v-if="allFeatureData.search !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllFeatures(allFeatureData.activityStatus, 'resetFilters')"/>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Package found.
                </template>
                <Column field="value" header="Feature Name" :sortable="true"></Column>

                <Column field="isMobile" header="Mobile View">
                    <template #body="slotProps">
                        <label v-if="slotProps.data.isMobile != null" :for="slotProps.data.id" :class="['sup_status_switch', slotProps.data.isMobile ? 'sup_status_active' : 'sup_status_inactive', {sup_status_switch_disable : !featureEdit}]">
                            <input :data-value="slotProps.data.isMobile" type="checkbox" role="switch" :id="slotProps.data.id" :checked="slotProps.data.isMobile">
                            <!-- <input v-else type="checkbox" role="switch" :id="slotProps.data.id"> -->
                            <span class="sup_switch_label" data-active="Yes" data-inactive="No" @click="isMobileChange(slotProps.data.id, slotProps.data.isMobile)"></span>
                            <span class="sup_switch_handle"></span>
                        </label>
                    </template>
                </Column>

                <Column field="compactMode" header="Compact Mode" headerStyle="width: 300px">
                    <template #body="slotProps">
                        <Dropdown v-model="slotProps.data.compactMode" :options="compactData" optionLabel="name" optionValue="name" placeholder="Select options" @change="compactModeChange(slotProps.data)" :disabled="!featureEdit" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show Package Type Table End --->
        </div>
    </div>
</template>

<script>
import { packageFeatures } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import {currentMenuUuid} from '../../../../helper/pageActions/pageActions';
import axios from "axios";
import { useStore } from 'vuex';
import { reactive, onBeforeMount, ref, computed } from 'vue';
export default {
    name: 'PackageFeatures',
    setup(){
        //Application store call
        const store = useStore();

        //Page Privilages data state
        const pagePrivilages = reactive({
            userRole: '',
            uuid: '',
            actions: ''
        });

        //Package Type data storing state
        const allFeatureData = reactive({
            data: [],
            total: null,
            status: null,
            loading: false,
            search: null,
            compactMode: '',
            addStatus: '',
            addMessage: '',
            editStatus: '',
            editMessage: '',
        });

        const compactData = ref([
            {name: "Desktop", value: "Desktop"},
            {name: "Mobile", value: "Mobile"},
            {name: "Both", value: "Both"},
        ]);
        const featureEdit = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.packagefeaturesEdit);

        (async () => {
            let { userRole, menuActions } = await currentMenuUuid('PackageFeatures');
            pagePrivilages.userRole = userRole;
            pagePrivilages.actions = menuActions;
        })();

        onBeforeMount( async() => {
            fetchAllFeatures();
        });

        const fetchAllFeatures = async (load) =>{
            allFeatureData.data = [];
            if(load === 'resetFilters'){
                allFeatureData.search = null;
            }

            try{
                allFeatureData.loading = !allFeatureData.loading;
                const response = await axios.get( packageFeatures, {
                    params: {
                        search: allFeatureData.search,
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                for (const key in response.data.data.features) {
                    allFeatureData.data.push(response.data.data.features[key]);
                }
                allFeatureData.total = allFeatureData.data.length;
                allFeatureData.loading = !allFeatureData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const compactModeChange = async (slotData) => {
            for (var i in allFeatureData.data) {
                if (allFeatureData.data[i].id == slotData.id) {
                    allFeatureData.data[i].compactMode = slotData.compactMode;
                    break; //Stop this loop, we found it!
                }
            }
            
            let NewFeatureObject = {};
            for (const key in allFeatureData.data) {
                NewFeatureObject[key] = allFeatureData.data[key];
            }

            try{
                const response = await axios.patch( packageFeatures, {
                    features: NewFeatureObject,
                }, { headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken,
                    },
                });
                if(response.status === 200) {
                    fetchAllFeatures();
                }
            } catch(err){
                console.log('err', err);
            }
        }

        const isMobileChange = async(id, status) => {
            
            let newArray = allFeatureData.data;
            for (var i in newArray) {
                if (newArray[i].id == id) {
                    newArray[i].isMobile = status === false ? true : false;
                    break; //Stop this loop, we found it!
                }
            }
            let NewFeatureObject = {};
            for (const key in newArray) {
                NewFeatureObject[key] = newArray[key];
            }

            try{
                const response = await axios.patch( packageFeatures, {
                    features: NewFeatureObject,
                }, { headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken,
                    },
                });
                if(response.status === 200) {
                    fetchAllFeatures();
                }
            } catch(err){
                console.log('err', err);
            }
        }

        return{
            featureEdit,
            allFeatureData,
            compactData,
            fetchAllFeatures,
            compactModeChange,
            isMobileChange
        }

    }
}
</script>

<style scoped>
.feature-list .p-dropdown{
    min-width: 200px;
}
</style>